<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title color="primary">{{ $t('user.nuevo') }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click.native="$emit('close-new-user')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-form ref="newProfessionalForm" v-model="validForm" class="form_usuario_nuevo pb-10">
      <v-layout wrap>
        <v-flex sm6>
          <v-text-field
            data-test="professional-input-name"
            v-model="usuario.nombre"
            class="mr-2"
            :label="$t('patient.first_name') + '*'"
            type="text"
            required
            filled
            outlined
            dense
            :rules="rules.nameOrSurname"
            @input="modificarSlug"
          />
        </v-flex>
        <v-flex sm6>
          <v-text-field
            data-test="professional-input-surname"
            v-model="usuario.apellidos"
            class="mr-2"
            :label="$t('patient.last_name') + '*'"
            type="text"
            required
            filled
            outlined
            dense
            :rules="rules.nameOrSurname"
            @input="modificarSlug"
          />
        </v-flex>
        <v-flex
          v-if="
            authUser.rol === 1 &&
              paymentSettings.isCustomPremium &&
              paymentSettings.premiumPaymentTypes &&
              paymentSettings.premiumPaymentTypes.length > 0
          "
          sm4
          lg4
        >
          <v-select
            data-test="professional-input-premium-payment-type"
            v-model="usuario.premium_payment_type_id"
            :items="paymentSettings.premiumPaymentTypes"
            :label="$t('empresa.premium_payment_type')"
            auto
            required
            filled
            outlined
            dense
            item-text="name"
            item-value="id"
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            data-test="professional-input-email"
            v-model="usuario.email"
            class="mr-2"
            :label="$t('message.email') + '*'"
            type="mail"
            required
            filled
            outlined
            dense
            :rules="rules.emailRequired"
          />
        </v-flex>
        <v-flex sm6 lg6 class="pr-2">
          <v-identify-document
            :dense="true"
            :filled="true"
            :id-document="usuario.id_document"
            :id-document-type="usuario.id_document_type"
            class="pr-1"
            use-required
            @onError="handleIdErrors"
            @input="setIdDocument"
          />
        </v-flex>

        <v-flex xs12 sm4>
          <v-tel-field
            data-test="professional-input-phone"
            v-model="usuario.telefono"
            :outlined="true"
            :dense="true"
            filled
            :placeholder="phonePlaceholder"
            class="mr-2"
            :required="isPhoneMandatoryForPatient"
            :rules="isPhoneMandatoryForPatient ? rules.requiredField : []"
            v-bind="bindPropsTel"
          />
        </v-flex>
        <v-flex xs12 sm4>
          <v-tel-field
            data-test="professional-input-phone-consultation"
            v-model="usuario.telefonoConsulta"
            :outlined="true"
            :dense="true"
            filled
            :placeholder="$t('user.telefono_consulta')"
            class="mr-2"
            :rules="rules.phone"
            v-bind="bindPropsTel"
          />
        </v-flex>
        <v-flex xs12 sm4>
          <v-menu
            ref="birthdate"
            v-model="birthdate"
            class="mr-2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            :return-value.sync="usuario.birthdate"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                slot="activator"
                v-model="dateFormatted"
                filled
                dense
                outlined
                class="mr-2"
                :label="$t('patient.birthdate')"
                append-icon="mdi-calendar"
                readonly
                v-on="on"
              />
            </template>
            <v-date-picker
              data-test="professional-input-birthdate"
              ref="picker"
              v-model="date"
              first-day-of-week="1"
              :locale="this.$i18n.locale"
              :min="datePickerMinDate"
              :max="new Date().toISOString().substr(0, 10)"
              no-title
              scrollable
              :allowed-dates="allowedDates"
              @input="$refs.birthdate.save(date)"
            />
          </v-menu>
        </v-flex>

        <v-flex v-show="showModule.specialties" sm12 lg12 class="pr-2">
          <div slot="widget-content">
            <v-select
              data-test="professional-input-specialties"
              ref="select"
              v-model="usuario.especialidades"
              :label="`${$t('user.especialidades')}*`"
              :items="especialidades"
              chips
              item-text="name"
              item-value="id"
              item-valor="id"
              multiple
              filled
              outlined
              dense
              :rules="rules.requiredField"
              @change="changeSpecialty"
            >
              <template slot="selection" slot-scope="data">
                <v-chip
                  :input-value="data.selected"
                  close
                  small
                  class="chip--select-multi"
                  color="blue lighten-4"
                  @click:close="removeEspecialidad(data.item.id)"
                >
                  {{ data.item['name'] }}
                </v-chip>
              </template>
              <template slot="item" slot-scope="data">
                <template v-if="!isObject(data.item)">
                  <v-list-item-content v-text="data.item" />
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item['name'] }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </div>
        </v-flex>

        <v-flex sm3 lg3 class="pr-2">
          <v-select
            data-test="professional-input-treatment"
            v-model="usuario.prefijo"
            :items="treatments"
            :label="$t('user.tipo_prof')"
            auto
            filled
            outlined
            dense
            item-text="name"
            item-value="id"
          />
        </v-flex>
        <v-flex v-show="showModule.collegiateNumber" sm5 lg5>
          <v-text-field
            data-test="professional-input-collegiate-number"
            v-model="usuario.colegiado"
            class="mr-2"
            :label="`${this.$t('user.num_colegiado')}*`"
            type="text"
            :rules="collegiatePsychologyRule ? rules.psychologistCollegiateNum : rules.collegiateNumber"
            filled
            outlined
            dense
          />
        </v-flex>
        <v-flex sm4 lg4 class="pr-2">
          <v-select
            data-test="professional-input-lang"
            v-model="usuario.lang"
            :items="languages"
            :label="$t('common.lang')"
            auto
            filled
            outlined
            dense
            item-text="name"
            item-value="id"
          />
        </v-flex>

        <v-flex v-if="!!companies.length" sm12 lg12 class="mr-2">
          <v-autocomplete
            data-test="professional-input-company"
            v-model="companyId"
            autocomplete="new-password"
            outlined
            dense
            filled
            clearable
            item-text="name"
            item-value="id"
            :label="$t('empresa.empresa')"
            :items="companies"
          />
        </v-flex>

        <v-flex v-show="showModule.healthCardNumber" xs12 sm6 lg12>
          <v-text-field
            data-test="professional-input-health-card-number"
            v-model="usuario.numsegsocial"
            class="mr-2"
            :label="$t('common.numero_seg_social')"
            type="text"
            filled
            outlined
            dense
          />
        </v-flex>

        <v-flex sm9 lg9>
          <v-text-field
            data-test="professional-input-address"
            v-model="usuario.direccion"
            class="mr-2"
            :label="$t('common.address')"
            type="text"
            filled
            outlined
            dense
          />
        </v-flex>
        <v-flex sm3 lg3>
          <v-text-field
            data-test="professional-input-postal-code"
            v-model="usuario.codigo_postal"
            class="mr-2"
            :label="$t('common.cp')"
            type="text"
            filled
            outlined
            dense
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            data-test="professional-input-location"
            v-model="usuario.localidad"
            class="mr-2"
            :label="$t('common.location')"
            type="text"
            filled
            outlined
            dense
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            data-test="professional-input-province"
            v-model="usuario.provincia"
            class="mr-2"
            :label="$t('common.province')"
            type="text"
            filled
            outlined
            dense
          />
        </v-flex>

        <v-flex sm6 lg6>
          <v-text-field
            data-test="professional-input-password"
            v-model="usuario.password"
            class="mr-2"
            :label="$t('message.password')"
            type="password"
            :rules="passwordRules"
            filled
            outlined
            dense
          />
        </v-flex>
        <v-flex sm6 lg6>
          <v-text-field
            data-test="professional-input-password-repeat"
            v-model="usuario.password_repeat"
            class="mr-2"
            :label="$t('user.password_repeat')"
            type="password"
            filled
            outlined
            dense
            :rules="rules.passwordMatches"
          />
        </v-flex>
        <v-flex xs12 class="text-right">
          <v-btn
            data-test="professional-button-cancel"
            color="grey darken-1"
            class="mr-2"
            text
            @click.native="handleModalClose"
          >
            {{ $t('common.cancel') }}
          </v-btn>
          <v-btn
            data-test="professional-button-save"
            color="primary"
            depressed
            text
            :loading="isLoading"
            :disabled="!computedValidForm"
            @click.native="validate"
          >
            {{ $t('common.save') }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { getHeader, createUsuarioUrl, defaultCountry } from '@/config/config';
import VIdentifyDocument from '../../modules/VuetifyIdentityDocument/components/VIdentifyDocument.vue';
import { safeCompanyId } from './new-professional.mapper';
import FormValidator from '@/mixins/FormValidation';
import { datePickerMinDate } from '@/services/dateHelper';
import { setSelectorsLanguages, isPhoneMandatoryForPatient, isPsychologistCollegiate } from '@/utils';
export default {
  name: 'NewProfessional',
  components: {
    VIdentifyDocument,
  },
  mixins: [FormValidator],
  props: {
    showModule: {
      type: Object,
      required: true,
    },
    especialidades: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      default: 0,
    },
    companies: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      validForm: false,
      isLoading: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      languages: [
        { id: 'es', name: vm.$t('common.spanish') },
        { id: 'en', name: vm.$t('common.english') },
        { id: 'pt', name: 'Portugués' },
        { id: 'pl', name: 'Polaco' },
        { id: 'ar', name: 'Arabe' },
      ],
      usuario: {
        valid: true,
        id: 0,
        nombre: '',
        apellidos: '',
        email: '',
        nombreusuario: '',
        colegiado: '',
        codigo_postal: '',
        direccion: '',
        localidad: '',
        provincia: '',
        id_document: '',
        id_document_type: 1,
        prefijo: 0,
        telefono: '',
        telefonoConsulta: '',
        lang: 'es',
        especialidades: [],
        password: '',
        password_repeat: '',
        numsegsocial: '',
        birthdate: null,
      },
      companyId: null,
      treatments: [
        { id: 0, name: vm.$t('common.none') },
        { id: vm.$t('user.female_doctor'), name: vm.$t('user.female_doctor') },
        { id: vm.$t('user.male_doctor'), name: vm.$t('user.male_doctor') },
      ],
      date: null,
      birthdate: false,
      dateFormatted: null,

      passwordRules: [
        v => {
          if (!v) return true;
          return this.containsLowercase(v) || this.$t('user.password_must_contain_lowercase');
        },
        v => {
          if (!v) return true;
          return this.containsUppercase(v) || this.$t('user.password_must_contain_uppercase');
        },
        v => {
          if (!v) return true;
          return this.containsNumbers(v) || this.$t('user.password_must_contain_numbers');
        },
        v => {
          if (!v) return true;
          return this.containsSymbols(v) || this.$t('user.password_must_contain_symbols');
        },
        v => {
          if (!v) return true;
          return v.length >= 8 || this.$t('user.password_min_length', { num: 8 });
        },
      ],
      rules: {
        passwordMatches: [this._matches(this, 'usuario.password')],
      },
      bindPropsTel: {
        mode: 'international',
        defaultCountry: JSON.parse(window.localStorage.getItem('auth_ehealth'))
          ? JSON.parse(window.localStorage.getItem('auth_ehealth'))['lang']
          : 'ES',
        preferredCountries: ['ES', 'FR', 'PT'],
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        required: false,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'phonePatient',
        maxLen: 17,
        wrapperClasses: '',
        inputClasses: '',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
      datePickerMinDate: datePickerMinDate(),
      isPhoneMandatoryForPatient: isPhoneMandatoryForPatient(),
      phonePlaceholder: `${this.$t('patient.phone')}*`,
      collegiatePsychologyRule: false,
    };
  },
  computed: {
    ...mapState({
      permissions: state => state.app.permissions,
      paymentSettings: state => state.app.paymentSettings,
    }),

    isObject() {
      return item => typeof item === 'object';
    },

    computedValidForm() {
      if (this.usuario.password && !this.usuario.password_repeat) {
        return false;
      }

      return this.validForm && this.usuario.id_document && this.usuario.especialidades.length;
    },
  },
  watch: {
    userId(val) {
      this.usuario.id = val;
    },
    date(_val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    birthdate(val) {
      val &&
        this.$nextTick(() => {
          this.$refs.picker.activePicker = 'YEAR';
          return;
        });
    },
  },
  created() {
    this.setDefaults();
  },
  mounted() {
    if (this.permissions.simplifiedLanguages === true) {
      this.languages = [
        { id: 'es', name: this.$t('common.spanish') },
        { id: 'en', name: this.$t('common.english') },
      ];
    }

    this.languages = setSelectorsLanguages(this.languages);
    this.setUserLanguage();
  },
  methods: {
    handleModalClose() {
      this.$refs.newProfessionalForm.resetValidation();
      this.$emit('close-new-user');
    },
    allowedDates(date) {
      return this.$moment().diff(date, 'years') >= 18;
    },

    modificarSlug() {
      let slug = '';
      if (this.usuario.prefijo !== '0' && this.usuario.prefijo !== 0 && this.usuario.prefijo !== '') {
        const pref = this.usuario.prefijo.replace('.', '');
        slug += pref.toLowerCase() + '-';
      }
      if (this.usuario.nombre !== '') {
        let nombre = this.usuario.nombre.trim();
        nombre = nombre.replace(/\s/g, '-');
        nombre = nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += nombre.toLowerCase() + '-';
      }
      if (this.usuario.apellidos !== '') {
        let apellidos = this.usuario.nombre.trim();
        apellidos = this.usuario.apellidos.replace(/\s/g, '-');
        apellidos = apellidos.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        slug += apellidos.toLowerCase();
      }
      this.usuario.slug = slug;
    },

    removeEspecialidad(item) {
      this.usuario.especialidades.splice(this.usuario.especialidades.indexOf(item), 1);
      this.usuario.especialidades = [...this.usuario.especialidades];
      this.changeRuleIfPsychology();
    },

    validate() {
      this.validForm = this.$refs.newProfessionalForm.validate();

      if (!this.validForm || !this.computedValidForm) {
        return;
      }

      this.createProfessional();
    },

    handleIdErrors() {
      this.$nextTick(() => {
        this.validForm = false;
      });
    },

    setIdDocument(data) {
      this.usuario.id_document = data.idDocument;
      this.usuario.id_document_type = data.idDocumentType;
    },

    changeSpecialty() {
      this.closeAfterSelect();
      this.changeRuleIfPsychology();
    },

    closeAfterSelect() {
      this.$refs.select.isMenuActive = false;
    },

    createProfessional() {
      try {
        this.isLoading = true;
        const companyIdAPI = safeCompanyId(this.companyId);

        this.$http
          .post(
            createUsuarioUrl,
            { id: this.authUser.id, usuario: this.usuario, company_id: companyIdAPI },
            { headers: getHeader() }
          )
          .then(response => {
            if (response.status === 200 && response.data.estado === 1) {
              this.$toast.success(this.$t('success.new_user'));
              this.$router.push('/professionals/' + response.data.id);
            } else if (response.data.estado === 2) {
              this.$toast.error(this.$t('errors.existing_user'));
            } else {
              this.$toast.error(this.$t('errors.try_again'));
            }
          })
          .catch(err => {
            let msg;
            if (err.body.error?.code === 'collegiate_number_in_use_in_company') {
              msg = this.$t('errors.collegiate_number_already_exists');
            } else if (err.body.error?.code === 'professional_already_exists') {
              msg = this.$t('errors.existing_user_in_company');
            } else {
              this.$t('errors.try_again');
            }

            this.$toast.error(msg);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } catch (error) {
        this.$toast.error(error);
      }
    },

    formatDate(date) {
      if (!date) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },

    containsLowercase(str) {
      return str && !!str.match(/[a-z]/);
    },
    containsUppercase(str) {
      return str && !!str.match(/[A-Z]/);
    },
    containsNumbers(str) {
      return str && !!str.match(/\d/);
    },
    containsSymbols(str) {
      return str && !!str.match(/\W/);
    },
    setDefaults() {
      this.phonePlaceholder = this.isPhoneMandatoryForPatient ? this.phonePlaceholder : this.$t('patient.phone');

      if (defaultCountry) {
        this.bindPropsTel.defaultCountry = defaultCountry;
        this.bindPropsTel.preferredCountries.unshift(defaultCountry);
      }
    },
    setUserLanguage() {
      this.usuario.lang = this.languages.length === 1 ? this.languages[0]['id'] : this.$i18n.locale;
    },

    changeRuleIfPsychology() {
      this.collegiatePsychologyRule = isPsychologistCollegiate(this.usuario.especialidades, this.especialidades);
    },
  },
};
</script>
<style>
.form_usuario_nuevo {
  padding: 10px 24px 10px 32px;
}

.label_genero {
  margin-bottom: 0px;
}

.radio_genero {
  margin-top: 3px;
}

.typeProfessional {
  margin-top: -16px;
}
</style>
